import { FC, useRef } from 'react'
import styles from './BasicHero.module.scss'
import { BasicHeroProps } from './BasicHero.types'
import { Badge } from 'components/Badge'
import { Button } from 'components/Phantom/Button'
import { Img } from 'components/basic/Img'
import { Type } from 'components/Type'

export const BasicHero: FC<BasicHeroProps> = (props) => {
	const { eyebrow, headline, subheadline, cta, imageSources, centered, shade } = props

	const containerRef = useRef<HTMLDivElement>(null)

	return (
		<section
			className={styles.container}
			ref={containerRef}
			data-centered={centered}
		>
			<header>
				<Badge.BFCMDark>{eyebrow}</Badge.BFCMDark>
				<Type.Headline1>{headline}</Type.Headline1>
				<Type.Headchapter>{subheadline}</Type.Headchapter>
				<Button.White
					id={'hero-cta'}
					href={cta.href}
				>
					{cta.text}
				</Button.White>
			</header>
			<div
				className={styles.media_container_basic}
				data-shade={shade}
			>
				<Img
					src={imageSources?.desktopSrc}
					alt={imageSources?.alt}
					sources={[
						{
							src: imageSources?.mobileSrc,
							mediaQuery: '(max-width: 1023px)',
							dprHeight: 1500,
						},
						{
							src: imageSources?.desktopSrc,
							mediaQuery: '(min-width: 1023px)',
							dprHeight: 1500,
						},
					]}
					objectFit={'cover'}
				/>
			</div>
		</section>
	)
}
